import { MediaTag } from '@app/models/media/mediaTag.model';
import { Media } from '@models/media/media.model';
import { SafeUrl } from '@angular/platform-browser';

export const formatMediaTags = (
  mediaTags: MediaTag[] | undefined,
): string[] | undefined => {
  return mediaTags?.map((tag) => `/media_tag/${tag.id}`);
};

export const getImageSafeUrl = (
  media?: Media,
  mediaUrls?: Record<string, SafeUrl | undefined>,
): SafeUrl | undefined => {
  if (mediaUrls) {
    const currentKey = media?.keyName ?? '';
    return mediaUrls[currentKey] ?? undefined;
  }
  return undefined;
};
