import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { computed, Injectable, signal, untracked } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Media } from '@app/models/media/media.model';
import { SafePipe } from '@app/pipes/safe.pipe';
import { formatMediaTags } from '@app/utils/media';
import { environement } from '@environments/environment';
import { ApiResponse } from '@models/common/api-response.model';
import { PageableResponse } from '@models/common/pageable-response.model';
import { MediaTypes } from '@models/media/media-types.models';
import { QuestionDetail } from '@models/question/question-detail.model';
import { VoiceSound } from '@models/voice-sound/voice-sound.model';
import { BaseService } from '@services/base.service';
import { ErrorService } from '@services/error.service';
import { PageRequest, PageResponse } from 'pf-ui';
import { MessageService } from 'primeng/api';
import { PaginatorState } from 'primeng/paginator';
import {
  catchError,
  finalize,
  from,
  map,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MediaService extends BaseService {
  signalList = signal<Media[] | undefined>(undefined);
  signalQuestionsList = signal<QuestionDetail[] | undefined>(undefined);
  paginatorMediaState = signal<PaginatorState>({});
  signalListPaginated = signal<PageResponse<Media> | undefined>(undefined);
  signalCurrent = signal<Media | null>(null);
  signalStaticVoices = signal<Array<VoiceSound>>([]);
  correctionIntroStaticVoices = signal<Array<VoiceSound>>([]);
  mediaUrls = signal<Record<string, SafeUrl | undefined> | undefined>(
    undefined,
  );

  signalImageLoaderByMedia = signal<Record<string, boolean> | undefined | null>(
    null,
  );

  signalImageLoader = computed(() => this.isQuestionLoadingUrlMedia());

  mediaUploadFrontError = 'Le média est invalide !';

  private isQuestionLoadingUrlMedia(): boolean {
    const currentLoaderByQuestion = this.signalImageLoaderByMedia();
    if (currentLoaderByQuestion === null) return false;
    if (currentLoaderByQuestion === undefined) return true;
    return !Object.values(currentLoaderByQuestion).every(
      (isLoading: boolean) => !isLoading,
    );
  }

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
    private readonly errorService: ErrorService,
    private readonly safePipe: SafePipe,
  ) {
    super(http, messageService);
  }

  setCurrent(media: Media): void {
    this.signalCurrent.set(media);
  }

  resetCurrent(): void {
    this.signalCurrent.set(null);
  }

  list(): Observable<Media[]> {
    return this.executeRequest(
      this.http.get<Media[]>(`${environement.BACKEND_URL}/medias`),
    ).pipe(
      tap((val) => {
        this.signalList.set(val);
      }),
    );
  }

  questionsList(mediaId: string): Observable<QuestionDetail[]> {
    return this.executeRequest(
      this.http.get<QuestionDetail[]>(
        `${environement.BACKEND_URL}/medias/${mediaId}/questions`,
      ),
    ).pipe(
      tap((questions) => {
        this.signalQuestionsList.set(questions);
      }),
    );
  }

  listPaginated(event?: PageRequest): Observable<PageResponse<Media>> {
    return this.executePaginatedRequest(
      this.http.get<ApiResponse<{ [key: string]: Media[] }>>(
        `${environement.BACKEND_URL}/media`,
        {
          params: this.getHttpParams({ ...event, projection: 'mediaList' }),
        },
      ),
    ).pipe(
      tap((pageResponse) => {
        this.signalListPaginated.set(pageResponse);
      }),
    );
  }

  getImage(keyName?: string): Observable<SafeUrl | undefined> {
    return this.http
      .get<Blob>(`${environement.BACKEND_URL}/medias/image/${keyName ?? ''}`, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((blob: Blob) => this.mapBlobToTrustedUrl(blob)),
        catchError((err) => {
          return this.errorService.handleErrors(err);
        }),
      );
  }

  getImageThumbnail(keyName?: string): Observable<SafeUrl | undefined> {
    return this.http
      .get<Blob>(
        `${environement.BACKEND_URL}/medias/image/thumbnail/${keyName ?? ''}`,
        {
          responseType: 'blob' as 'json',
        },
      )
      .pipe(
        tap(() => {
          if (keyName) {
            this.signalImageLoaderByMedia.update((current) => ({
              ...current,
              [keyName]: true,
            }));
          }
        }),
        map((blob: Blob) => this.mapBlobToTrustedUrl(blob)),
        catchError((err) => {
          return this.errorService.handleErrors(err);
        }),
        finalize(() => {
          if (keyName) {
            this.signalImageLoaderByMedia.update((current) => ({
              ...current,
              [keyName]: false,
            }));
          }
        }),
      );
  }

  getAudio(keyName?: string): Observable<string> {
    return this.http
      .get<Blob>(`${environement.BACKEND_URL}/medias/audio/${keyName ?? ''}`, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((blob: Blob) => this.mapBlobToAudioUrl(blob)),
        catchError((err) => {
          return this.errorService.handleErrors(err);
        }),
      );
  }

  public mapBlobToTrustedUrl = (blob: Blob): SafeUrl | undefined => {
    const objectURL = URL.createObjectURL(blob);
    return this.safePipe.transform(objectURL);
  };

  public mapBlobToAudioUrl = (blob: Blob): string => {
    return URL.createObjectURL(blob);
  };

  get(id: string): void {
    this.executeRequest(
      this.http.get<Media>(`${environement.BACKEND_URL}/media/${id}`),
    ).subscribe((val) => {
      this.signalCurrent.set(val);
    });
  }

  post(
    media: Media,
    type: MediaTypes,
    callbackError?: (err?: HttpErrorResponse) => Observable<never>,
  ): Observable<Media> {
    return this.mapBody(media, type).pipe(
      switchMap((data) => {
        const headers = new HttpHeaders({ enctype: 'multipart/form-data' });

        return this.executeRequest(
          this.http.post<Media>(
            `${environement.BACKEND_URL}/medias/upload?projection=mediaList`,
            data,
            {
              headers,
            },
          ),
          { callbackError: callbackError },
        );
      }),
      tap((val) => this.signalCurrent.set(val)),
    );
  }

  put(media: Media): Observable<Media> {
    return this.executeRequest(
      this.http.patch<Media>(`${environement.BACKEND_URL}/media/${media.id}`, {
        ...media,
        mediaTags: formatMediaTags(media.mediaTags),
      }),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  findByTags(
    event?: PageRequest,
    endpoint: string = 'tags',
  ): Observable<PageResponse<Media>> {
    const params = this.getHttpParams({ ...event });
    this.signalImageLoaderByMedia.set(undefined);
    return this.http
      .get<PageableResponse<Media>>(
        `${environement.BACKEND_URL}/medias/${endpoint}`,
        {
          params,
        },
      )
      .pipe(
        this.mapPageableResponseToPageResponse<Media>(),
        tap((val) => {
          this.signalListPaginated.set(val);
          this.signalImageLoaderByMedia.set(this.computedLoaderByImageId(val));
          this.signalList.set(val.result as Media[] | undefined);
        }),
      );
  }

  findMediaByTags(event?: PageRequest): Observable<PageResponse<Media>> {
    return this.findByTags(event, 'byTags');
  }

  mapBody(media: Media, type: MediaTypes): Observable<FormData> {
    const mediaName = media.name;
    const mediaBase64 = media.base64_file;

    if (
      mediaName == null ||
      (media.base64_file == null && media.keyName == null)
    )
      return throwError(() => this.mediaUploadFrontError);

    const mimeType =
      mediaName?.endsWith('.png') === true ? 'image/png' : 'image/jpg';
    const totalBase64 = `data:${mimeType};base64,${mediaBase64}`;

    return from(fetch(totalBase64)).pipe(
      switchMap((base64res) => {
        return from(base64res.blob());
      }),
      switchMap((blob) => {
        const file = new File([blob], mediaName);
        const formData = new FormData();

        media.base64_file = undefined;
        media.type = type;

        formData.append('file', file, file.name);
        formData.append(
          'media',
          new Blob([JSON.stringify(media)], { type: 'application/json' }),
        );

        return of(formData);
      }),
    );
  }

  private computedLoaderByImageId(
    mediaPageResponse: PageResponse<Media>,
  ): Record<string, boolean> | undefined {
    if (mediaPageResponse === undefined) {
      return undefined;
    }
    return mediaPageResponse.result.reduce((accu, current) => {
      const picture = current as Media;
      if (!picture) return accu;
      accu = { ...accu, [picture.keyName ?? '']: true };
      return accu;
    }, {}) as Record<string, boolean>;
  }

  fetchStaticVoices(): Observable<{
    answerIntroVoices: VoiceSound[];
    correctionIntroVoices: VoiceSound[];
  }> {
    return this.executeRequest(
      this.http.get<{
        answerIntroVoices: VoiceSound[];
        correctionIntroVoices: VoiceSound[];
      }>(`${environement.BACKEND_URL}/custom-voice-sound/static-voices`),
    ).pipe(
      tap((voiceSounds) => {
        this.signalStaticVoices.set(voiceSounds.answerIntroVoices);
        this.correctionIntroStaticVoices.set(voiceSounds.correctionIntroVoices);
      }),
    );
  }

  getCorrectionIntroStaticVoices(
    questionNumber: number,
  ): VoiceSound | undefined {
    return untracked(() => this.correctionIntroStaticVoices()).find(
      (voiceSound) =>
        voiceSound.text === 'Question numéro ' + questionNumber + ' ',
    );
  }
}
